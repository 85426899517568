import React, { useState } from 'react';
import { Text } from '@dropbox/dig-components/dist/typography';
import { Button, IconButton } from '@dropbox/dig-components/dist/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { useDecision } from '@optimizely/react-sdk';
import { setCookieClientSide } from 'lib/cookies';
import Cookies from 'js-cookie';
import {
  useGetUserDisclosureDismissedOnQuery,
  useGetDocumentInfoQuery,
} from 'lib/graphql/Static/__generated__/StaticQueries.generated';
import { useSetDisclosureDismissedOnMutation } from 'lib/graphql/Static/__generated__/StaticMutations.generated';
import { useRouter } from 'next/router';
import { StaticQuery } from 'lib/static/StaticTypes';
import styles from './FormDisclosureText.module.scss';

export const CLOSED_FORM_DISCLOSURE_COOKIE_NAME = 'closedFormDisclosure';
const CLOSED_FORM_DISCLOSURE_TRUE = 'true';

const DisclosureText = ({
  onClose,
  variationKey,
}: {
  variationKey: string | null;
  onClose: () => void;
}): JSX.Element | null => {
  const SHORT_TEXT =
    'This form is provided for convenience only and is not a substitute for legal advice.';
  const LONG_TEXT =
    "This document is provided for your convenience, but isn't a substitute for an attorney's advice or protected by attorney-client privilege or as work product. We don't guarantee the legal enforceability of this document in your specific situation. If you need legal advice, consult with an attorney.";
  const FRIENDLY_TEXT =
    'FormSwift is dedicated to helping individuals and small businesses customize, sign, and download essential documents. Please note that this document is provided for convenience only and is not a substitute for legal advice.';

  switch (variationKey) {
    case 'variation_2':
      return (
        <Text color="faint" size="small">
          {`${SHORT_TEXT} `}
          <Button onClick={onClose} size="small" variant="transparent">
            Got It
          </Button>
        </Text>
      );
    case 'variation_3':
      return (
        <Text color="faint" size="small">
          {`${LONG_TEXT} `}
          <Button onClick={onClose} size="small" variant="transparent">
            Got It
          </Button>
        </Text>
      );
    case 'variation_4':
      return (
        <Text color="faint" size="small">
          {`${FRIENDLY_TEXT} `}
          <Button onClick={onClose} size="small" variant="transparent">
            Got It
          </Button>
        </Text>
      );
    case 'variation_5':
      return (
        <Text color="faint" size="small">
          {`${SHORT_TEXT} `}
        </Text>
      );
    case 'variation_6':
      return (
        <Text color="faint" size="small">
          {`${LONG_TEXT} `}
        </Text>
      );
    case 'variation_7':
      return (
        <Text color="faint" size="small">
          {`${FRIENDLY_TEXT} `}
        </Text>
      );
    default:
      return null;
  }
};

const DisclosureWrapper = ({
  children,
  onClose,
  variationKey,
}: {
  children: JSX.Element;
  variationKey: string;
  onClose: () => void;
}): JSX.Element | null => {
  if (['variation_2', 'variation_3', 'variation_4'].includes(variationKey)) {
    return (
      <div className={styles['disclosure-text-wrapper']}>
        <div className={styles['disclosure-text']}>{children}</div>
      </div>
    );
  }
  if (['variation_5', 'variation_6', 'variation_7'].includes(variationKey)) {
    return (
      <div className={styles['disclosure-notification']}>
        <div className={styles['disclosure-notification-text-wrapper']}>
          {children}
        </div>
        <div>
          <IconButton onClick={onClose} size="small" variant="borderless">
            <UIIcon aria-label="Close" src={CloseLine} />
          </IconButton>
        </div>
      </div>
    );
  }
  return null;
};

const useDisclosureCloseState = (): [boolean, () => void] => {
  const userId = Cookies.get('userid');
  const userToken = Cookies.get('userToken');
  const isLoggedIn = userId && userToken;

  const { user } = useGetUserDisclosureDismissedOnQuery().data || {};
  const [setDisclosureDismissedOn] = useSetDisclosureDismissedOnMutation();
  const closedFormDisclosureCookieValue = Cookies.get(
    CLOSED_FORM_DISCLOSURE_COOKIE_NAME
  );
  const isDisclosureDismissedCookieSet =
    closedFormDisclosureCookieValue === CLOSED_FORM_DISCLOSURE_TRUE;
  const isDisclosureDismissedInDb = user ? !!user.disclosureDismissedOn : true;
  const isAlreadyDismissed = isLoggedIn
    ? isDisclosureDismissedInDb
    : isDisclosureDismissedCookieSet;
  const closedFormDisclosureInitialState = isLoggedIn
    ? true
    : isDisclosureDismissedCookieSet;
  const [closedFormDisclosure, setClosedFormDisclosure] = useState(
    closedFormDisclosureInitialState
  );
  if (isAlreadyDismissed !== closedFormDisclosure) {
    setClosedFormDisclosure(isAlreadyDismissed);
  }

  const handleClose = () => {
    if (isLoggedIn) {
      void setDisclosureDismissedOn();
    } else {
      setCookieClientSide(
        CLOSED_FORM_DISCLOSURE_COOKIE_NAME,
        CLOSED_FORM_DISCLOSURE_TRUE,
        { expires: 365 }
      );
    }
    setClosedFormDisclosure(true);
  };

  return [closedFormDisclosure, handleClose];
};

export const FormDisclosureText = (): JSX.Element | null => {
  const router = useRouter();
  const { documentType } = router.query as StaticQuery;
  const [formDisclosureDecision] = useDecision(
    'product_form_disclosure_for_legal_static_docs'
  );
  const { variationKey } = formDisclosureDecision;
  const { documentInfo } =
    useGetDocumentInfoQuery({ variables: { documentType } }).data || {};
  const [closedFormDisclosure, handleClose] = useDisclosureCloseState();

  const isLegalDoc = documentInfo?.isLegalDoc === 'yes';
  const isSplitTestVariation = !!variationKey && variationKey !== 'variation_1';
  const showFormDisclosureText =
    !closedFormDisclosure && isSplitTestVariation && isLegalDoc;

  return showFormDisclosureText ? (
    <DisclosureWrapper onClose={handleClose} variationKey={variationKey}>
      <DisclosureText onClose={handleClose} variationKey={variationKey} />
    </DisclosureWrapper>
  ) : null;
};
