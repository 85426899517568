import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import { CredentialResponse } from 'GoogleSignIn/types';
import { NextRouter } from 'next/router';
import { track } from 'lib/tracking/useTrack';
import { googleOneTapPromptNotificationHandler } from 'GoogleSignIn/googleOneTapPromptNotificationHandler';

const { publicRuntimeConfig } = getConfig();
const { API_URL, INTERNAL_API_URL } = publicRuntimeConfig;

const handleError = (router: NextRouter, apiErrorMessage: string | null) => {
  const error = apiErrorMessage || 'registration-failed-google';
  const documentType = (router.query.documentType as string) || '';
  const oneTapPromptCallback = googleOneTapPromptNotificationHandler(
    `${documentType} | new-account`,
    track
  );

  try {
    google.accounts.id.prompt(oneTapPromptCallback);
  } catch (e) {
    console.log(e);
  }

  if (error === 'registration-failed') {
    void track({
      action: `${documentType} | new-account`,
      category: 'API Account Exists | One Tap',
    });
  }
  void track({
    action: `${documentType} | new-account | ${error || ''}`,
    category: 'API Error | One Tap',
  });

  const isWarning = error === 'sign-in-invalid';
  void router.push(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
        ...(isWarning ? { warning: error } : { error }),
      },
    },
    undefined,
    { shallow: true }
  );
};

export const checkNoAccountExists = async (
  data: CredentialResponse,
  router: NextRouter
): Promise<boolean> => {
  const formSwiftAPIClient = new FormSwiftAPIClient(API_URL, INTERNAL_API_URL);

  try {
    const response = (await formSwiftAPIClient.request(
      'POST',
      '/user/validate/register/third-party/google',
      { ...data },
      true
    )) as Response;
    switch (response.status) {
      case 200: {
        return true;
      }
      case 400: {
        const parsedErrorResponse = (await response.json()) as {
          message: string | null;
        };
        handleError(router, parsedErrorResponse.message);
        return false;
      }
      default:
        handleError(router, null);
        return false;
    }
  } catch (e) {
    handleError(router, null);
    return false;
  }
};
