import { v4 as uuidv4 } from 'uuid';
import { isomorphicSetCookie } from 'IsomorphicUtils';
import { OptimizelyData, OptimizelyDatafile } from 'lib/optimizely/types';
import { NextPageContext } from 'next';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';
import { isEqual } from 'lodash';

const { publicRuntimeConfig } = getConfig();
let cachedDatafile: OptimizelyDatafile | undefined;

const getOptimizelyData = async (
  ctx: NextPageContext,
  cookies: { [key: string]: string }
): Promise<OptimizelyData> => {
  // Get the optimizelyUserId associated with the user.
  // We store a first-party cookie `optimizelyUserId` to manage variations
  // for both logged-out and logged-in users.
  let { optimizelyUserId }: { [key: string]: string } = cookies;
  if (!optimizelyUserId) {
    optimizelyUserId = uuidv4();
    isomorphicSetCookie('optimizelyUserId', optimizelyUserId, ctx);
  }

  // Fetch the optimizely datafile. Required for SSR
  let datafile: OptimizelyDatafile | undefined;
  try {
    // We want to terminate fetching the datafile after 3s so we create
    // two promises with one being the datafile fetch and the other
    // configured to reject after 3s
    const resp = fetch(
      // TODO: Consider fixing linting error when editing file next
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      `https://cdn.optimizely.com/datafiles/${publicRuntimeConfig.OPTIMIZELY_SDK_KEY}.json`
    );

    const timeLimitedPromise = new Promise<void>((resolve, reject) => {
      setTimeout(() => reject(new Error('Timeout')), 3000);
    });

    // Timeout datafile fetching after 3s. Here, we use `Promise.race` to
    // wait on either the fetch call to resolve or our 3s timeout,
    // `timeLimitedPromise`, to reject - whichever comes first.
    const response = await Promise.race([resp, timeLimitedPromise]);
    datafile = await response?.json();
    // The datafile is cached here to maintain a consistent reference
    // This prevents unnecessary updates in the react lifecycle.
    if (!isEqual(datafile, cachedDatafile)) {
      cachedDatafile = datafile;
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return { datafile: cachedDatafile, optimizelyUserId };
};

// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getOptimizelyData;
