import { combineReducers } from 'redux';
import {
  emailMarketingConsentReducer,
  googleSignUpReducer,
} from 'NewAccountForm/newAccountFormState';
import { CheckoutHandler } from 'CheckoutPage/state/Reducers';
// TODO: Fix usage of deprecated reducer when it makes sense to refactor
import { DocumentLibraryHandler } from 'DocumentLibrary/reducers';
import { MyAccountHandler } from 'MyAccount/MyAccountModals/Legacy/Reducers';
// eslint-disable-next-line regex/invalid
import AppHandler from '../components/App/Reducers';
import ChooseFaxNumberHandler from '../components/ChooseFaxNumber/Reducers';
import TrackingHandler from '../components/Tracking/Reducers';
import UploadContainerHandler from '../components/UploadContainer/Reducers';
// TODO: Fix usage of deprecated reducer when it makes sense to refactor
// eslint-disable-next-line regex/invalid
import UserHandler from '../components/User/Reducers';

export const rootReducer = combineReducers({
  // TODO: Fix usage of deprecated reducer when it makes sense to refactor
  // eslint-disable-next-line regex/invalid
  AppHandler,
  CheckoutHandler: CheckoutHandler.reducer,
  ChooseFaxNumberHandler,
  DocumentLibraryHandler,
  emailMarketingConsent: emailMarketingConsentReducer,
  googleSignUp: googleSignUpReducer,
  MyAccountHandler: MyAccountHandler.reducer,
  TrackingHandler,
  UploadContainerHandler,
  // TODO: Fix usage of deprecated reducer when it makes sense to refactor
  // eslint-disable-next-line regex/invalid
  UserHandler,
});
