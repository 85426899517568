import FormSwiftAPIClient from 'FormSwiftAPIClient';
import getConfig from 'next/config';
import { generateQueryString, RedirectPages } from 'Utils';
import { ErrorMessageConstants } from 'ErrorMessage/ErrorMessageUtils';
import { Router } from 'next/router';
import { ServerResponse } from 'http';

const {
  publicRuntimeConfig: { API_URL, DOMAIN_URL, INTERNAL_API_URL },
} = getConfig();
const formSwiftAPIClient = new FormSwiftAPIClient(API_URL, INTERNAL_API_URL);

export const getCheckoutFormFields = async ({
  address = '',
  address2 = '',
  city = '',
  country = '',
  documentType = '',
  faxNumber = null,
  firstName,
  fetchOptions = undefined,
  isFreeWrite = false,
  isInternationalEnabled = false,
  isTestPurchase = false,
  lastName,
  organization,
  plan,
  state = '',
  userId,
  userToken,
  vatNumber,
  zipCode = '',
}: {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  firstName?: string;
  lastName?: string;
  userId: number;
  userToken: string;
  plan: string;
  organization?: string;
  documentType: string;
  fetchOptions?: Partial<RequestInit>;
  isFreeWrite: boolean;
  isInternationalEnabled?: boolean;
  isTestPurchase?: boolean;
  faxNumber?: string | null;
  state?: string | null;
  vatNumber?: string;
  zipCode?: string | null;
}): Promise<
  | {
      status: 'success';
      data: { chargify: Record<string, unknown> };
    }
  | {
      message: string;
      status: 'error';
      data: { chargify: Record<string, unknown> };
    }
> => {
  const paymentPlan = plan || 0;

  const queryObj = {
    ...(address ? { address } : {}),
    ...(address2 ? { address_2: address2 } : {}),
    ...(city ? { city } : {}),
    ...(country ? { country } : {}),
    ...(state ? { state } : {}),
    ...(faxNumber ? { fax_number: faxNumber } : {}),
    ...(isFreeWrite ? { freewrite: 1 } : {}),
    ...(isTestPurchase ? { test_purchase: 1 } : {}),
    ...(lastName ? { last_name: lastName } : {}),
    ...(firstName ? { first_name: firstName } : {}),
    ...(isInternationalEnabled ? { international_enabled: true } : {}),
    ...(organization ? { organization } : {}),
    ...(vatNumber ? { vat_number: vatNumber } : {}),
    document_type: documentType,
    user_token: userToken,
    ...(zipCode ? { zip_code: zipCode } : {}),
  };

  const checkoutFieldsRequestURL = `/billing/${
    DOMAIN_URL as string
  }/checkout-fields/${userId}/${paymentPlan}`;
  const resp = await formSwiftAPIClient.request(
    'GET',
    checkoutFieldsRequestURL,
    queryObj,
    false,
    true,
    fetchOptions
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return resp;
};

export const getCheckoutQueryString = (
  newPage: string,
  queryParams = {}
): string => {
  if (newPage === RedirectPages.SIGN_IN) {
    return generateQueryString({
      ...queryParams,
      error: ErrorMessageConstants.CHECKOUT_SIGN_IN_REDIRECT,
    });
  }
  return generateQueryString(queryParams);
};

export const clientRedirect = (
  newPage: string,
  router: Router,
  queryParams = {}
): void => {
  const queryString = getCheckoutQueryString(newPage, queryParams);
  void router.push(`//${DOMAIN_URL as string}/${newPage}?${queryString}`);
};

export const serverRedirect = (
  newPage: string,
  res: ServerResponse,
  queryParams = {}
): void => {
  const queryString = getCheckoutQueryString(newPage, queryParams);
  void res.writeHead(302, {
    Location: `//${DOMAIN_URL as string}/${newPage}?${queryString}`,
  });
  res.end();
};
