import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetDisclosureDismissedOnMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SetDisclosureDismissedOnMutation = {
  __typename?: 'Mutation';
  setDisclosureDismissedOn?: {
    __typename?: 'SetDisclosureDismissedOn';
    ok?: boolean | null;
    user?: {
      __typename?: 'User';
      id: string;
      disclosureDismissedOn?: string | null;
    } | null;
  } | null;
};

export const SetDisclosureDismissedOnDocument = gql`
  mutation SetDisclosureDismissedOn {
    setDisclosureDismissedOn {
      ok
      user {
        id
        disclosureDismissedOn
      }
    }
  }
`;
export type SetDisclosureDismissedOnMutationFn = Apollo.MutationFunction<
  SetDisclosureDismissedOnMutation,
  SetDisclosureDismissedOnMutationVariables
>;

/**
 * __useSetDisclosureDismissedOnMutation__
 *
 * To run a mutation, you first call `useSetDisclosureDismissedOnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDisclosureDismissedOnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDisclosureDismissedOnMutation, { data, loading, error }] = useSetDisclosureDismissedOnMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetDisclosureDismissedOnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDisclosureDismissedOnMutation,
    SetDisclosureDismissedOnMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDisclosureDismissedOnMutation,
    SetDisclosureDismissedOnMutationVariables
  >(SetDisclosureDismissedOnDocument, options);
}
export type SetDisclosureDismissedOnMutationHookResult = ReturnType<
  typeof useSetDisclosureDismissedOnMutation
>;
export type SetDisclosureDismissedOnMutationResult = Apollo.MutationResult<SetDisclosureDismissedOnMutation>;
export type SetDisclosureDismissedOnMutationOptions = Apollo.BaseMutationOptions<
  SetDisclosureDismissedOnMutation,
  SetDisclosureDismissedOnMutationVariables
>;
