import React from 'react';

export const EmailExistsMessage = (
  email?: string,
  queryString?: string
): JSX.Element => {
  const parsedQueryString = queryString?.replace(/&{0,1}error[^&]*/, '');
  return (
    <>
      A FormSwift account for that email address already exists. Please
      <a
        href={`/sign-in?${
          typeof parsedQueryString !== 'undefined' ? parsedQueryString : ''
        }`}
      >
        &nbsp;sign in here.&nbsp;
      </a>
    </>
  );
};
