import { createInstance, enums, ReactSDKClient } from '@optimizely/react-sdk';
import { isEmpty, isEqual } from 'lodash';
import { OptimizelyDatafile } from 'lib/optimizely/types';

let cachedOptimizelyClient: ReactSDKClient | null;
let cachedDatafile: OptimizelyDatafile;
const cachedNotificationListeners: {
  notificationType: enums.NOTIFICATION_TYPES;
  // TODO: Consider fixing linting error next time editing this file
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (notificationInfo: any) => void;
}[] = [];

/**
 * Gets an optimizely client instance. If one has already been initialized and the
 * datafile has not changed this will return the cached client.
 * We create a new instance when the datafile changes as this indicates a change in our
 * optimizely configuration - this happens when new tests are created.
 * @param datafile: the Optimizely data file
 * @param options: {
 *    @param notificationListeners: A list of notification listener configuration objects
 *    in the form {notificationType, callback}. These should be memoized where possible
 * }
 */
const getOptimizelyClient = (
  datafile: OptimizelyDatafile,
  options: {
    notificationListeners?: {
      notificationType: enums.NOTIFICATION_TYPES;
      // TODO: Consider fixing linting error next time editing this file
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (notificationInfo: any) => void;
    }[];
  } = {}
): ReactSDKClient => {
  const { notificationListeners = [] } = options;
  const didNotificationListenersChange = !isEqual(
    notificationListeners,
    cachedNotificationListeners
  );
  let didDatafileChange = false;

  // If the datafile is defined and not empty, update the
  // cached datafile if it changed; we only cache valid datafiles.
  // This ensures we never overwrite valid datafiles.
  if (datafile && !isEmpty(datafile)) {
    didDatafileChange = !isEqual(datafile, cachedDatafile);
    if (didDatafileChange) {
      cachedDatafile = datafile;
    }
  }

  // Create an optimizelyClient only if there isn't one already or
  // if the data file changed; if there isn't a cachedOptimizelyClient
  // and the datafile is undefined or empty, just create a
  // cachedOptimizelyClient with an empty datafile. This way, every page
  // will always have an optimizelyClient with either
  // an empty datafile or a valid one fetched from optimizely.
  if (!cachedOptimizelyClient || didDatafileChange) {
    void cachedOptimizelyClient?.close();
    cachedOptimizelyClient = createInstance({
      datafile: datafile || {},
    });
  }

  // https://github.com/optimizely/react-sdk/issues/87
  // https://github.com/optimizely/react-sdk/issues/87#issuecomment-881506263
  // There are problems registering notification listeners after the client is ready
  if (didDatafileChange || didNotificationListenersChange) {
    cachedOptimizelyClient?.notificationCenter?.clearAllNotificationListeners();
    notificationListeners.forEach(({ callback, notificationType }) => {
      cachedOptimizelyClient?.notificationCenter?.addNotificationListener(
        notificationType,
        callback
      );
    });
  }

  return cachedOptimizelyClient;
};

export const clearCachedClient = (): void => {
  cachedOptimizelyClient = null;
};
// If you see this ignore please consider refactoring to a named export
// eslint-disable-next-line import/no-default-export
export default getOptimizelyClient;
