import { FullStory, init, isInitialized } from '@fullstory/browser';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const FORMSWIFT_ORG_ID = 'o-1WJPRF-na1';

const FULLSTORY_ENABLED_PAGES = [
  {
    STATIC_PAGE: '/builder/static/',
  },
];

// Use the debug script when running in a non-production environment to avoid polluting sessions with dev data
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isProd = publicRuntimeConfig.APP_ENV === 'production';

// FullStory expects the script URL to not include the protocol (eg. https://)
// FullStory will add the protocol themselves when making the request so we need to remove it from the asset prefix
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
const FULLSTORY_DEBUG_SCRIPT = `${publicRuntimeConfig.ASSET_PREFIX}/static/fs-debug.js`.replace(
  'https://',
  ''
);
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
const FULLSTORY_SCRIPT = `${publicRuntimeConfig.ASSET_PREFIX}/static/fs.js`.replace(
  'https://',
  ''
);

export const initializeFullStory = () => {
  if (isInitialized()) {
    // If FullStory was previously initialized before being disabled for any reason, it must be restarted rather than
    // re-initialized
    FullStory('restart');
    return;
  }
  const script = isProd ? FULLSTORY_SCRIPT : FULLSTORY_DEBUG_SCRIPT;
  init({ orgId: FORMSWIFT_ORG_ID, script });
};

export const disableFullStory = () => {
  if (isInitialized()) {
    FullStory('shutdown');
  }
};

export const privacyConsentFullStoryInit = () => {
  if (
    FULLSTORY_ENABLED_PAGES.some((page) =>
      window.location.pathname.startsWith(Object.values(page)[0])
    )
  ) {
    initializeFullStory();
  }
};
