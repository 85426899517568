import * as Types from '../../schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserBillingDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserBillingDetailsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    credits?: number | null;
    hasPaidAccess?: boolean | null;
    id: string;
    isCancelled?: boolean | null;
    isFreemium?: boolean | null;
  } | null;
};

export type DocumentLibrarySearchQueryVariables = Types.Exact<{
  category?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  searchTerm: Types.Scalars['String'];
}>;

export type DocumentLibrarySearchQuery = {
  __typename?: 'Query';
  documentSearch?: {
    __typename?: 'DocumentSearch';
    documents?: Array<{
      __typename?: 'DocumentInfo';
      documentLabel?: string | null;
    } | null> | null;
  } | null;
};

export type GetDocumentInfoQueryVariables = Types.Exact<{
  documentType: Types.Scalars['String'];
}>;

export type GetDocumentInfoQuery = {
  __typename?: 'Query';
  documentInfo?: {
    __typename?: 'DocumentInfo';
    isLegalDoc?: string | null;
  } | null;
};

export type GetUserDisclosureDismissedOnQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserDisclosureDismissedOnQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    disclosureDismissedOn?: string | null;
    id: string;
  } | null;
};

export const GetUserBillingDetailsDocument = gql`
  query getUserBillingDetails {
    user {
      credits
      hasPaidAccess
      id
      isCancelled
      isFreemium
    }
  }
`;

/**
 * __useGetUserBillingDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBillingDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserBillingDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserBillingDetailsQuery,
    GetUserBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserBillingDetailsQuery,
    GetUserBillingDetailsQueryVariables
  >(GetUserBillingDetailsDocument, options);
}
export function useGetUserBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserBillingDetailsQuery,
    GetUserBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserBillingDetailsQuery,
    GetUserBillingDetailsQueryVariables
  >(GetUserBillingDetailsDocument, options);
}
export type GetUserBillingDetailsQueryHookResult = ReturnType<
  typeof useGetUserBillingDetailsQuery
>;
export type GetUserBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUserBillingDetailsLazyQuery
>;
export type GetUserBillingDetailsQueryResult = Apollo.QueryResult<
  GetUserBillingDetailsQuery,
  GetUserBillingDetailsQueryVariables
>;
export const DocumentLibrarySearchDocument = gql`
  query documentLibrarySearch(
    $category: String
    $limit: Int
    $searchTerm: String!
  ) {
    documentSearch(
      category: $category
      limit: $limit
      searchTerm: $searchTerm
    ) {
      documents {
        documentLabel
      }
    }
  }
`;

/**
 * __useDocumentLibrarySearchQuery__
 *
 * To run a query within a React component, call `useDocumentLibrarySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentLibrarySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentLibrarySearchQuery({
 *   variables: {
 *      category: // value for 'category'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDocumentLibrarySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    DocumentLibrarySearchQuery,
    DocumentLibrarySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DocumentLibrarySearchQuery,
    DocumentLibrarySearchQueryVariables
  >(DocumentLibrarySearchDocument, options);
}
export function useDocumentLibrarySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentLibrarySearchQuery,
    DocumentLibrarySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DocumentLibrarySearchQuery,
    DocumentLibrarySearchQueryVariables
  >(DocumentLibrarySearchDocument, options);
}
export type DocumentLibrarySearchQueryHookResult = ReturnType<
  typeof useDocumentLibrarySearchQuery
>;
export type DocumentLibrarySearchLazyQueryHookResult = ReturnType<
  typeof useDocumentLibrarySearchLazyQuery
>;
export type DocumentLibrarySearchQueryResult = Apollo.QueryResult<
  DocumentLibrarySearchQuery,
  DocumentLibrarySearchQueryVariables
>;
export const GetDocumentInfoDocument = gql`
  query getDocumentInfo($documentType: String!) {
    documentInfo(documentType: $documentType) {
      isLegalDoc
    }
  }
`;

/**
 * __useGetDocumentInfoQuery__
 *
 * To run a query within a React component, call `useGetDocumentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentInfoQuery({
 *   variables: {
 *      documentType: // value for 'documentType'
 *   },
 * });
 */
export function useGetDocumentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentInfoQuery,
    GetDocumentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDocumentInfoQuery, GetDocumentInfoQueryVariables>(
    GetDocumentInfoDocument,
    options
  );
}
export function useGetDocumentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentInfoQuery,
    GetDocumentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocumentInfoQuery,
    GetDocumentInfoQueryVariables
  >(GetDocumentInfoDocument, options);
}
export type GetDocumentInfoQueryHookResult = ReturnType<
  typeof useGetDocumentInfoQuery
>;
export type GetDocumentInfoLazyQueryHookResult = ReturnType<
  typeof useGetDocumentInfoLazyQuery
>;
export type GetDocumentInfoQueryResult = Apollo.QueryResult<
  GetDocumentInfoQuery,
  GetDocumentInfoQueryVariables
>;
export const GetUserDisclosureDismissedOnDocument = gql`
  query getUserDisclosureDismissedOn {
    user {
      disclosureDismissedOn
      id
    }
  }
`;

/**
 * __useGetUserDisclosureDismissedOnQuery__
 *
 * To run a query within a React component, call `useGetUserDisclosureDismissedOnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDisclosureDismissedOnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDisclosureDismissedOnQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDisclosureDismissedOnQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDisclosureDismissedOnQuery,
    GetUserDisclosureDismissedOnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserDisclosureDismissedOnQuery,
    GetUserDisclosureDismissedOnQueryVariables
  >(GetUserDisclosureDismissedOnDocument, options);
}
export function useGetUserDisclosureDismissedOnLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDisclosureDismissedOnQuery,
    GetUserDisclosureDismissedOnQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserDisclosureDismissedOnQuery,
    GetUserDisclosureDismissedOnQueryVariables
  >(GetUserDisclosureDismissedOnDocument, options);
}
export type GetUserDisclosureDismissedOnQueryHookResult = ReturnType<
  typeof useGetUserDisclosureDismissedOnQuery
>;
export type GetUserDisclosureDismissedOnLazyQueryHookResult = ReturnType<
  typeof useGetUserDisclosureDismissedOnLazyQuery
>;
export type GetUserDisclosureDismissedOnQueryResult = Apollo.QueryResult<
  GetUserDisclosureDismissedOnQuery,
  GetUserDisclosureDismissedOnQueryVariables
>;
