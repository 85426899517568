import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { countries, CountryCode } from './countries';

export type CountryState = {
  country: string;
};

export const defaultCountryState: CountryState = {
  country: 'us',
};

export const getInitialCountry = (
  detectedCountry: string,
  showInternational = false
) => {
  let adjustedCountry = detectedCountry.toLowerCase();
  if (adjustedCountry === 'gb') {
    adjustedCountry = 'uk';
  }
  const codes = countries.map((country) => country.code);
  const initialCountry =
    // @ts-expect-error codes might not include detectedCountry
    showInternational && codes.includes(adjustedCountry)
      ? (adjustedCountry as CountryCode)
      : 'us';
  return initialCountry;
};

const countrySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action) => {
      const retypedAction = (action as unknown) as {
        payload: { DocumentLibraryHandler: CountryState };
      };
      Object.assign(state, retypedAction.payload.DocumentLibraryHandler);
    });
  },
  initialState: defaultCountryState,
  name: 'country',
  reducers: {
    setCountry: (state, action) => {
      const country = action.payload;
      state.country = country;
    },
  },
});

const { setCountry } = countrySlice.actions;
export { setCountry };
export const DocumentLibraryHandler = countrySlice.reducer;
