import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkAPI } from 'store/ThunkAPIType';
import { RootState } from 'store/RootState';
import getConfig from 'next/config';
import * as Sentry from '@sentry/nextjs';
import { isomorphicGetCookie } from 'IsomorphicUtils';
import Router from 'next/router';
import { track } from 'lib/tracking/useTrack';
import { initApollo } from 'lib/apollo';
import { GET_USER_EMAIL } from 'FreeWrite/gql/getUserEmail';
import { refreshChargifyJSSecurityToken } from './refreshChargifyJSSecurityToken';

const {
  publicRuntimeConfig: { API_URL, DOMAIN_URL },
} = getConfig();

export const subscribe = createAsyncThunk<
  Awaited<unknown>,
  { token: string },
  AppThunkAPI & { value: string }
>('subscribe', async (data, thunkAPI) => {
  const state: RootState = thunkAPI.getState();
  try {
    const paymentProfileResponse = await fetch(
      `${API_URL as string}/payment-profile`,
      {
        body: JSON.stringify({
          chargify_domain: state.CheckoutHandler.chargify.domain,
          chargify_token: data.token,
          customer_attributes: {
            address: state.CheckoutHandler.address,
            address_2: state.CheckoutHandler.address2,
            city: state.CheckoutHandler.city,
            country: state.CheckoutHandler.country,
            first_name: state.CheckoutHandler.firstName,
            last_name: state.CheckoutHandler.lastName,
            organization: state.CheckoutHandler.organization,
            state: state.CheckoutHandler.state,
            vat_number: state.CheckoutHandler.vatNumber,
            zip: state.CheckoutHandler.zipCode,
          },
          user_id: isomorphicGetCookie('userid'),
          user_token: isomorphicGetCookie('userToken'),
        }),
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
        method: 'POST',
      }
    );
    const paymentProfileJson = await paymentProfileResponse.json();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (paymentProfileJson.status !== 'success') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      Sentry.captureMessage(paymentProfileJson.message);
      // recoverable, refresh chargify token and try again
      // eslint-disable-next-line no-alert
      window.alert(
        'Something went wrong. Please refresh the page and try again.'
      );
      const chargifyDomain = thunkAPI.getState().CheckoutHandler.chargify
        .domain as string;
      await thunkAPI.dispatch(
        refreshChargifyJSSecurityToken({ chargifyDomain })
      );
      return thunkAPI.rejectWithValue(
        'Something went wrong. Please try again or contact customer service.'
      );
    }

    const {
      client_secret: clientSecret,
      customer_id: customerId,
      payment_profile_id: paymentProfileId,
      stripe_publishable_key: stripePublishableKey,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } = paymentProfileJson?.data;

    if (clientSecret && typeof stripePublishableKey === 'string') {
      const stripe = window.Stripe(stripePublishableKey);
      const stripeResult = await stripe.confirmSetup({
        clientSecret,
        confirmParams: {
          return_url: `https://${DOMAIN_URL as string}/checkout?3dsecure=true`,
        },
        redirect: 'if_required',
      });

      if (stripeResult?.setupIntent?.status !== 'succeeded') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        Sentry.captureMessage(paymentProfileJson.message);
        // recoverable, refresh chargify token and try again
        // eslint-disable-next-line no-alert
        window.alert(
          'Something went wrong. Please refresh the page and try again.'
        );
        const chargifyDomain = thunkAPI.getState().CheckoutHandler.chargify
          .domain as string;
        await thunkAPI.dispatch(
          refreshChargifyJSSecurityToken({ chargifyDomain })
        );
        return thunkAPI.rejectWithValue(
          'Something went wrong. Please try again or contact customer service.'
        );
      }
    }

    const requestData = {
      chargify_domain: state.CheckoutHandler.chargify.domain,
      currency: state.CheckoutHandler.chargify.currency,
      customer_id: customerId,
      document_type: Router.query.documentType,
      fax_number: Router.query.fax_number,
      is_b2b: state.CheckoutHandler.isB2B ? 'true' : 'false',
      payment_profile_id: paymentProfileId,
      plan: Router.query.plan || 0,
      product_handle: state.CheckoutHandler.chargify.product_handle,
      product_price_point_id:
        state.CheckoutHandler.chargify.product_price_point_id,
      session_hash: Router.query.ses,
      session_id: Router.query.key,
      site: DOMAIN_URL,
      user_id: isomorphicGetCookie('userid'),
      user_token: isomorphicGetCookie('userToken'),
    };
    const response = await fetch(`${API_URL as string}/subscribe`, {
      body: JSON.stringify(requestData),
      headers: {
        'content-type': 'application/json;charset=UTF-8',
      },
      method: 'POST',
    });
    const json = await response.json();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (json.status === 'success') {
      try {
        const apolloClient = initApollo({}, '');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const result = await apolloClient.query({ query: GET_USER_EMAIL });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const email = result.data.user.email ?? undefined;
        await track({
          action: 'Complete your order',
          category: 'Checkout',
          email,
          userId: parseInt(isomorphicGetCookie('user_id') as string, 10),
        });
      } catch (e) {
        Sentry.captureException(e);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
      await Router.push(json?.data.redirect_url);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    } else if (json.status === 'unprocessable_content') {
      // 3DSecure Flow
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      await Router.push(json?.data.redirect_url as string);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      Sentry.captureMessage(json.message);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (json.data?.redirect_url) {
        // Unrecoverable error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        await Router.push(json.data.redirect_url as string);
      } else {
        // recoverable, refresh chargify token and try again
        // eslint-disable-next-line no-alert
        window.alert(
          'Something went wrong. Please refresh the page and try again.'
        );
        const chargifyDomain = thunkAPI.getState().CheckoutHandler.chargify
          .domain as string;
        await thunkAPI.dispatch(
          refreshChargifyJSSecurityToken({ chargifyDomain })
        );
        return thunkAPI.rejectWithValue(
          'Something went wrong. Please try again or contact customer service.'
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw new Error(json.message as string);
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  return null;
});
