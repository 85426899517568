import { GoogleSignInWithOneTapProps } from 'GoogleSignIn/types';
import useTrack from 'lib/tracking/useTrack';

export const googleOneTapPromptNotificationHandler = (
  // Currently the action for all events is same. Update this param if needed.
  oneTapTrackingAction: GoogleSignInWithOneTapProps['oneTapTrackingAction'] = '',
  track: ReturnType<typeof useTrack>
): ((notification: google.accounts.id.PromptMomentNotification) => void) => {
  return (moment: google.accounts.id.PromptMomentNotification) => {
    /**
     * `moment.isDisplayMoment()` will be true for popup displayed and not displayed scenarios.
     * moment.isDisplayed() confirms that the popup is displayed.
     */
    const trackOneTapDisplayed =
      moment.isDisplayMoment() && moment.isDisplayed() && oneTapTrackingAction;
    /**
     * When user clicks continue for any account, Google will verify the account and close the popup.
     * Then a dismiss moment will be triggered and w/ reason `credential_returned`.
     * Dismissed moment gets triggered also for "cancel_called" | "flow_restarted".
     */
    const trackOneTapContinued =
      moment.isDismissedMoment() &&
      moment.getDismissedReason() === 'credential_returned' &&
      oneTapTrackingAction;
    /**
     * Skipped moment w/ reason "user_cancel" is triggered when user cancels the one tap using close icon.
     * We disabled closing OneTap when user tap outside One Tap.
     * Skipped moment is also triggered for, "auto_cancel" | "tap_outside" | "issuing_failed"
     */
    const trackOneTapClosed =
      moment.isSkippedMoment() &&
      moment.getSkippedReason() === 'user_cancel' &&
      oneTapTrackingAction;

    if (trackOneTapDisplayed) {
      void track({
        action: oneTapTrackingAction,
        category: 'View | One Tap',
      });
    }
    if (trackOneTapContinued) {
      void track({
        action: oneTapTrackingAction,
        category: 'Continue | One Tap',
      });
    }
    if (trackOneTapClosed) {
      void track({
        action: oneTapTrackingAction,
        category: 'Close | One Tap',
      });
    }
  };
};
